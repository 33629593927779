import '../public/styles.css';

// import '@exactsciences/ph-web-components/dist/index';
// import { initConfig, localizationConfig } from '@helix-design-system/components/dist/components/localization-provider/localization-provider.js';
// initConfig(new Map([
//     ['es', Promise.all([
//         import(`/node_modules/@exactsciences/ph-web-components/dist/generated/locales/es.js`),
//         import(`/node_modules/@helix-design-system/components/dist/generated/locales/es.js`),
//     ])],
// ]));
// initConfig(new Map([
//     ['es', import(`/node_modules/@helix-design-system/components/dist/generated/locales/es.js`)],
// ]));
// console.log('Initial locale: ', localizationConfig.getLocale());
// localizationConfig.setLocale('es').then(() => {
//     console.log('New locale: ', localizationConfig.getLocale());
// });

import '@exactsciences/ph-web-components/dist/components/header/ESHeader.js';
import '@exactsciences/ph-web-components/dist/components/footer/ESFooter.js';
import '@helix-design-system/components/dist/components/accordion/index.js';
import '@helix-design-system/components/dist/components/alert/index.js';
import '@helix-design-system/components/dist/components/badge/index.js';
import '@helix-design-system/components/dist/components/button/index.js';
import '@helix-design-system/components/dist/components/card/index.js';
import '@helix-design-system/components/dist/components/carousel/index.js';
import '@helix-design-system/components/dist/components/checkbox/index.js';
import '@helix-design-system/components/dist/components/date-picker/index.js';
import '@helix-design-system/components/dist/components/divider/index.js';
import '@helix-design-system/components/dist/components/error-mapping/index.js';
import '@helix-design-system/components/dist/components/icon/index.js';
import '@helix-design-system/components/dist/components/icon-special/index.js';
import '@helix-design-system/components/dist/components/menu/index.js';
import '@helix-design-system/components/dist/components/notification-bar/index.js';
import '@helix-design-system/components/dist/components/one-time-code/index.js';
import '@helix-design-system/components/dist/components/password/index.js';
import '@helix-design-system/components/dist/components/phone/index.js';
import '@helix-design-system/components/dist/components/radio-group/index.js';
import '@helix-design-system/components/dist/components/select/index.js';
import '@helix-design-system/components/dist/components/spinner/index.js';
import '@helix-design-system/components/dist/components/tab-group/index.js';
import '@helix-design-system/components/dist/components/tag/index.js';
import '@helix-design-system/components/dist/components/text-field/index.js';
import '@helix-design-system/components/dist/components/textarea/index.js';
import '@helix-design-system/components/dist/components/toggle/index.js';
import '@helix-design-system/components/dist/components/toggletip/index.js';
import '@helix-design-system/components/dist/components/video-overlay/index.js';
import('./bootstrap');
